/**
 * Anti coller sur un champ
 *
 * data-cab-anti-paste="true" pour activer l'anti coller sur un champ
 */


['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, antiPaste, false))

function antiPaste() {
    let antiPasteFields = document.querySelectorAll('[data-cab-anti-paste="true"]')
    antiPasteFields.forEach(function(antiPasteField) {
        antiPasteField.addEventListener('paste', antiPasteEventListener)
        antiPasteField.addEventListener('paste', antiPasteEventListener)
    })
}

function antiPasteEventListener(event) {
    event.preventDefault();
    return false;
}