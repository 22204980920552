/**
 * Creation modal confirmation au retour
 */
export default function ajaxLoading() {

    /**
     * Loading pour ajax
     */
    $(document)
        .ajaxStart(() => {
            $('#cab-loading').removeClass('d-none');

        })
        .ajaxStop(() => {
            $('#cab-loading').addClass('d-none');
        });
}