/**
 * Gestion des notyf avec les messages flash de symfony
 *
 * data-cab-flash-message="true" pour activer la notyf sur le message flash
 * data-cab-flash-message-type="" info|success|warning|danger
 * data-cab-flash-message-content=""
 *
 */

import '../notyf/notyf-config';

document.addEventListener('DOMContentLoaded', function (event) {
    let flashMessages = document.querySelectorAll('[data-cab-flash-message="true"]');

    flashMessages.forEach((flashMessage) => {
        notyf.open({
            type: flashMessage.dataset.cabFlashMessageType,
            message: flashMessage.dataset.cabFlashMessageContent
        });

        flashMessage.remove();
    });
});