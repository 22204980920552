/**
 * Gestion des champs requis pour ajouter *
 *
 * Ajoute automatiquement * sur un champ requis
 */

['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, formRequired, false))

const requiredIcon = "<span> * </span>";

function formRequired()
{
    document.querySelectorAll("label.form-label.required").forEach( element => {
        if (element.innerHTML.includes(requiredIcon)) {
            return;
        }
        element.innerHTML = element.innerHTML + requiredIcon;
    })

    document.querySelectorAll("legend.required").forEach( element => {
        if (element.innerHTML.includes(requiredIcon)) {
            return;
        }
        element.innerHTML = element.innerHTML + requiredIcon;
    })
}
