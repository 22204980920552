/**
 * Affichage loading quand click sur un bouton
 *
 * data-cab-loading-add="true"
 */

['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, addLoading))

function addLoading() {
    let addLoadingButtons = document.querySelectorAll('[data-cab-loading-add="true"]');

    addLoadingButtons.forEach((button) => {
        button.addEventListener('click', function () {
            document.querySelector('#cab-loading').classList.remove('d-none');
        })
    });
}