import Inputmask from "inputmask";

window.Inputmask = Inputmask;

['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, eventInputMask, false))

function eventInputMask()
{
    Inputmask().mask(document.querySelectorAll('input'));
}

