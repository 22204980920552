/**
 * Gestion des hide/show/toggle
 *
 * data-cab-hide="true" pour cacher un container
 * data-cab-hide-selector="" pour le container a cacher
 *
 * data-cab-show="true" pour afficher un container
 * data-cab-show-selector="" pour le container a afficher
 *
 * data-cab-toggle="true" pour toggle un container
 * data-cab-toggle-selector="" pour le container a toggle
 */
['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, hideShowToggleEvent, false));

function hideShowToggleEvent() {
    let hideButtons = document.querySelectorAll('[data-cab-hide=\'true\']');
    hideButtons.forEach(function (hideButton) {
        hideButton.removeEventListener('click', listenerHide);
        hideButton.addEventListener('click', listenerHide);
    });

    let showButtons = document.querySelectorAll('[data-cab-show=\'true\']');
    showButtons.forEach(function (showButton) {
        showButton.removeEventListener('click', listenerShow);
        showButton.addEventListener('click', listenerShow);
    });

    let toggleButtons = document.querySelectorAll('[data-cab-toggle=\'true\']');
    toggleButtons.forEach(function (toggleButton) {
        toggleButton.removeEventListener('click', listenerToogle);
        toggleButton.addEventListener('click', listenerToogle);
    });
}

function listenerShow(event) {
    const showContainer = document.querySelector(event.target.dataset.cabShowSelector);
    showContainer.classList.remove('d-none');
}

function listenerHide(event) {
    const hideContainer = document.querySelector(event.target.dataset.cabHideSelector);
    hideContainer.classList.add('d-none');
}

function listenerToogle(event) {
    const toggleContainer = document.querySelector(event.target.dataset.cabToggleSelector);
    toggleContainer.classList.toggle('d-none');
}