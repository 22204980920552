import "../../adminkit/js/modules/bootstrap";

/**
 * Gestion des modal
 *
 * data-cab-modal=true
 * data-cab-modal-type="" confirmation-retour || confirmation-message || confirmation-checkbox || confirmation-suppression
 *
 * facultatif
 * data-cab-modal-copy-text=""
 * data-cab-modal-checkbox-text=""
 * data-cab-modal-title=""
 * data-cab-modal-text=""
 * data-cab-modal-button-valid-text=""
 * data-cab-modal-button-valid-class=""
 * data-cab-modal-button-cancel-text=""
 * data-cab-modal-button-cancel-class=""
 *
 */

['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, eventModal));

let configConfirmationRetour = {
    'title': 'Quitter cette page',
    'text': 'Etes-vous sûr de vouloir quitter sans sauvegarder les modifications ?',
    'type': 'confirmation-retour',
    'copy': false,
    'copyText': null,
    'checkbox': false,
    'checkboxText': null,
    'buttonValidText': 'Oui',
    'buttonValidClass': 'btn btn-danger',
    'buttonCancelText': 'Non',
    'buttonCancelClass': 'btn btn-outline-primary',
};

let configConfirmationMessage = {
    'title': 'Effectuer cette action',
    'text': 'Etes-vous sûr de vouloir effectuer cette action ?',
    'type': 'confirmation-message',
    'copy': false,
    'copyText': null,
    'checkbox': false,
    'checkboxText': null,
    'buttonValidText': 'Oui',
    'buttonValidClass': 'btn btn-danger',
    'buttonCancelText': 'Non',
    'buttonCancelClass': 'btn btn-outline-primary',
};

let configConfirmationCheckbox = {
    'title': 'Confirmation',
    'text': 'Etes-vous sûr de vouloir valider cette action ?',
    'type': 'confirmation-checkbox',
    'copy': false,
    'copyText': null,
    'checkbox': true,
    'checkboxText': 'Je confirme vouloir faire cette action',
    'buttonValidText': 'Oui',
    'buttonValidClass': 'btn btn-primary',
    'buttonCancelText': 'Non',
    'buttonCancelClass': 'btn btn-outline-secondary',
};

let configConfirmationSuppression = {
    'title': 'Confirmation de suppression',
    'text': 'Etes-vous sûr de vouloir effectuer la suppression ?',
    'type': 'confirmation-suppression',
    'copy': true,
    'copyText': 'supprimer',
    'checkbox': false,
    'checkboxText': null,
    'buttonValidText': 'Supprimer',
    'buttonValidClass': 'btn btn-danger',
    'buttonCancelText': 'Annuler',
    'buttonCancelClass': 'btn btn-outline-secondary',
};

let elementClickIsForm = false;

function eventModal() {
    // default config
    let config = configConfirmationMessage;
    let modalContainer = document.querySelector('[data-cab-modal-container="true"]');
    let modalBootstrap = new bootstrap.Modal(modalContainer, []);

    // bouton annuler
    let modalCancelButton = document.querySelector('[data-cab-modal-container-button-cancel="true"]');
    modalCancelButton.addEventListener('click', (event) => {
        clearModal(modalContainer, config);
        modalBootstrap.hide();
    });

    // bouton close
    let modalCloseButton = document.querySelector('[data-cab-modal-container-button-close="true"]');
    modalCloseButton.addEventListener('click', (event) => {
        clearModal(modalContainer, config);
        modalBootstrap.hide();
    });


    // tous les boutons qui lance la modal
    let modalButtons = document.querySelectorAll('[data-cab-modal="true"]');
    modalButtons.forEach((modalButton) => {

        // ajout event sur bouton de lancement
        modalButton.addEventListener('click', function (event) {
            event.preventDefault();
            let $this = event.target;
            if (event.target.type === 'submit') {
                $this = $this.closest('form');
                elementClickIsForm = true;
            }
            let modalType = $this.dataset.cabModalType || null;

            if ('confirmation-retour' === modalType) {
                config = configConfirmationRetour;
            }

            if ('confirmation-message' === modalType) {
                config = configConfirmationMessage;
            }

            if ('confirmation-checkbox' === modalType) {
                config = configConfirmationCheckbox;
                config.checkboxText = $this.dataset.cabModalCheckboxText ?? config.checkboxText;
            }

            if ('confirmation-suppression' === modalType) {
                config = configConfirmationSuppression;
                config.copyText = $this.dataset.cabModalCopyText ?? config.copyText;
            }

            // reprise modif par data-cab-modal
            config.text = $this.dataset.cabModalText ?? config.text;
            config.title = $this.dataset.cabModalTitle ?? config.title;
            config.buttonValidText = $this.dataset.cabModalButtonValidText ?? config.buttonValidText;
            config.buttonValidClass = $this.dataset.cabModalButtonValidClass ?? config.buttonValidClass;
            config.buttonCancelText = $this.dataset.cabModalButtonCancelText ?? config.buttonCancelText;
            config.buttonCancelClass = $this.dataset.cabModalButtonCancelClass ?? config.buttonCancelClass;

            initModal(modalContainer, config);

            // affichage de la modal
            modalBootstrap.show();

            // mettre le focus
            modalContainer.addEventListener('shown.bs.modal', () => {focusInput(modalContainer, config); });


            // event on click valider
            modalContainer.querySelector('[data-cab-modal-container-button-valid="true"]')
                .addEventListener('click', () => {
                    let hideModal = true;

                    // cache les erreurs avant les verifs
                    hideErrors(modalContainer);

                    if (true === config.copy) {
                        if (modalContainer.querySelector('[data-cab-modal-container-copy-input="true"]').value.trim() !== config.copyText.trim()) {
                            hideModal = false;
                            modalContainer.querySelector('[data-cab-modal-container-copy-error="true"]').className = 'd-block';
                        }
                    }

                    if (true === config.checkbox) {
                        if (modalContainer.querySelector('[data-cab-modal-container-checkbox-input="true"]').checked === false) {
                            hideModal = false;
                            modalContainer.querySelector('[data-cab-modal-container-checkbox-error="true"]').className = 'd-block';
                        }
                    }

                    if (true === hideModal) {
                        modalBootstrap.hide();
                        if (false === elementClickIsForm) {
                            window.location = event.target.href;
                        } else {
                            $this.querySelector('[type="submit"]').setAttribute('disabled', 'disabled');
                            $this.submit();
                        }

                        // ou submit form
                        document.querySelector('#cab-loading').classList.remove('d-none');
                    }
                });

        });
    });

}

function initModal(modalContainer, config) {
    modalContainer.querySelector('[data-cab-modal-container-copy="true"]').className = 'd-none';
    modalContainer.querySelector('[data-cab-modal-container-copy-text="true"]').textContent = '';

    modalContainer.querySelector('[data-cab-modal-container-checkbox="true"]').className = 'd-none';
    modalContainer.querySelector('[data-cab-modal-container-checkbox-text="true"]').textContent = '';
    modalContainer.querySelector('[data-cab-modal-container-checkbox-input="true"]').checked = false;

    modalContainer.querySelector('[data-cab-modal-container-title="true"]').textContent = config.title;
    modalContainer.querySelector('[data-cab-modal-container-text="true"]').textContent = config.text;


    modalContainer.querySelector('[data-cab-modal-container-button-valid="true"]').className = config.buttonValidClass;
    modalContainer.querySelector('[data-cab-modal-container-button-valid="true"]').textContent = config.buttonValidText;

    modalContainer.querySelector('[data-cab-modal-container-button-cancel="true"]').className = config.buttonCancelClass;
    modalContainer.querySelector('[data-cab-modal-container-button-cancel="true"]').textContent = config.buttonCancelText;

    if (config.type === 'confirmation-checkbox') {
        initConfirmationCheckbox(modalContainer, config);
    }

    if (config.type === 'confirmation-suppression') {
        initConfirmationSuppression(modalContainer, config);
    }
}

function initConfirmationCheckbox(modalContainer, config) {
    modalContainer.querySelector('[data-cab-modal-container-checkbox="true"]').className = 'd-block';
    modalContainer.querySelector('[data-cab-modal-container-checkbox-error="true"]').className = 'd-none';
    modalContainer.querySelector('[data-cab-modal-container-checkbox-text="true"]').textContent = config.checkboxText;
}

function initConfirmationSuppression(modalContainer, config) {
    modalContainer.querySelector('[data-cab-modal-container-copy="true"]').className = 'd-block';
    modalContainer.querySelector('[data-cab-modal-container-copy-error="true"]').className = 'd-none';
    modalContainer.querySelector('[data-cab-modal-container-copy-text="true"]').textContent = config.copyText;
}


function clearEvent(modalContainer) {
    modalContainer.removeEventListener('hidden.bs.modal', clearModal);
    modalContainer.removeEventListener('shown.bs.modal', focusInput);
}

function clearModal(modalContainer) {
    hideErrors(modalContainer);
    clearEvent(modalContainer);
    elementClickIsForm = false;
}

function focusInput(modalContainer, config) {
    if (true === config.copy) {
        modalContainer.querySelector('[data-cab-modal-container-copy-input="true"]').focus();
    }
}

function hideErrors(modalContainer) {
    modalContainer.querySelector('[data-cab-modal-container-checkbox-error="true"]').className = 'd-none';
    modalContainer.querySelector('[data-cab-modal-container-copy-error="true"]').className = 'd-none';
}
