// extensions
import "../extensions/theme/theme";
import "../extensions/inputMask/inputMask";
import "../extensions/flash-message-to-notyf/flash-message-to-notyf";
import "../extensions/modal/modal";
import "../extensions/form/form-collection";
import "../extensions/form/form-required";
import "../extensions/form/form-help";
import "../extensions/hide-show-toggle/hide-show-toggle";
import "../extensions/choicesjs/choicesjs";
import "../extensions/loading/button-loading";
import "../extensions/anti-paste/anti-paste";


import ajaxLoading from "../extensions/loading/ajax-loading";
ajaxLoading();







