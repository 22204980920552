/**
 * Gestion des messages help
 *
 * Ajoute automatiquement l'icone pour l'aide sur les form
 */

['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, formHelp, false))


function formHelp()
{
    event.target.querySelectorAll("p.form-text.help-text").forEach( element => {
        const helpIcon = "<i class=\"fas fa-info-circle\"></i> ";
        if (element.innerHTML.includes(helpIcon)) {
            return;
        }
        element.innerHTML = helpIcon + element.innerHTML;
    })
}