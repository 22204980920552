import { Notyf } from 'notyf';

window.notyf = new Notyf({
    duration: 5000,
    position: {
        x: 'right',
        y: 'top'
    },
    types: [
        {
            type: 'info',
            backgroundColor: '#3B7DDD',
            dismissible: true,
            duration: 5000,
            icon: {
                className: 'fas fa-info-circle',
                tagName: 'i',
                color: 'white'
            }
        },
        {
            type: 'success',
            backgroundColor: '#28a745',
            dismissible: true,
            duration: 5000,
            icon: {
                className: 'notyf__icon--success',
                tagName: 'i',
            }
        },
        {
            type: 'warning',
            backgroundColor: '#ffc107',
            dismissible: true,
            duration: 10000,
            icon: {
                className: 'fas fa-exclamation-triangle',
                tagName: 'i',
                color: 'white'
            }
        },
        {
            type: 'danger',
            backgroundColor: '#dc3545',
            dismissible: true,
            duration: 10000,
            icon: {
                className: 'notyf__icon--error',
                tagName: 'i',
            }
        }
    ]
});