import '../notyf/notyf-config';
/**
 * Gestion des form collections
 *
 * -sur le div qui va contenir toute la collection
 * data-cab-form-collection="true"
 * data-cab-form-collection-item-max="3" nombre max d'items
 * data-cab-form-collection-item-min="3" nombre min d'items
 * data-cab-form-collection-item-index="{{ form.contacts|length > 0 ? form.contacts|last.vars.name + 1 : 0 }}"
 * data-cab-form-collection-name="" nom de la collection
 * data-cab-form-collection-name-prototype="" nom de la collection pour le prototype
 * data-cab-form-collection-prototype="" prototype du form collection
 * --facultatif--
 * data-cab-form-collection-message-min="" message erreur pour min atteint
 * data-cab-form-collection-message-max="" message erreur pour max atteint
 * data-cab-form-collection-message-up-erreur="" message erreur pour up erreur
 * data-cab-form-collection-order="true" pour activer l'ordre
 * data-cab-form-collection-order-field="true" sur le champ qui va accueillir l'ordre
 *
 * -sur le div qui va contenir tous les items de la collection (doit etre dans le div qui englobe toute la collection)
 * data-cab-form-collection-items="nom de la collection"
 *
 * -sur le div qui va contenir un item de la collection (doit etre dans le div items qui est dans le div de la collection)
 * data-cab-form-collection-item="nom de la collection"
 *
 * sur le bouton ou a qui va ajouter un item dans la collection (doit etre dans le div qui engloble toute la collection)
 * data-cab-form-collection-button-add="nom de la collection"
 */

document.addEventListener('DOMContentLoaded', function (event) {

    initialize();

    document
        .querySelectorAll('[data-cab-form-collection-button-add]')
        .forEach(buttonAdd => {
            buttonAdd.addEventListener('click', addItemToCollectionEventListener);
        });
});

document.addEventListener('addItemToCollectionEvent', () => {
    document
        .querySelectorAll('[data-cab-form-collection-button-add]')
        .forEach(buttonAdd => {
            buttonAdd.removeEventListener('click', addItemToCollectionEventListener);
            buttonAdd.addEventListener('click', addItemToCollectionEventListener);
        });
});

function initialize() {
    document
        .querySelectorAll('[data-cab-form-collection="true"]')
        .forEach(formCollection => {
            const formCollectionItems = formCollection.querySelectorAll('[data-cab-form-collection-item="' + formCollection.dataset.cabFormCollectionName + '"]');
            formCollectionItems.forEach(formCollectionItem => {
                formCollectionItem.appendChild(buttonsForItemCreate(formCollection));
            });
        });

    const cabEvent = new Event('cabEvent', {bubbles: true});
    document.dispatchEvent(cabEvent);
}


function addItemToCollectionEventListener(event) {
    event.preventDefault();
    const buttonAdd = event.currentTarget;
    const currentCollectionName = buttonAdd.dataset.cabFormCollectionButtonAdd;
    const currentCollection = document.querySelector('[data-cab-form-collection-name="' + currentCollectionName + '"]');
    const currentCollectionPrototypeName = currentCollection.dataset.cabFormCollectionPrototypeName ?? '__name__';
    const items = document.querySelector('[data-cab-form-collection-items="' + currentCollectionName + '"]');
    const numberItemMax = currentCollection.dataset.cabFormCollectionItemMax ?? 10;
    const messageMax = currentCollection.dataset.cabFormCollectionMessageMax ?? 'Vous ne pouvez plus ajouter d\'élément !';

    if (parseInt(currentCollection.dataset.cabFormCollectionItemIndex) + 1 > numberItemMax) {
        notyf.open({
            type: 'danger',
            message: messageMax + ' (maximum : ' + numberItemMax + ')'
        });
        return;
    }


    let itemToAdd = currentCollection
        .dataset
        .cabFormCollectionPrototype
        .replace(
            new RegExp(currentCollectionPrototypeName, 'g'),
            currentCollection.dataset.cabFormCollectionItemIndex
        );

    itemToAdd = stringToDom(itemToAdd);
    addFocus(itemToAdd);
    itemToAdd.appendChild(buttonsForItemCreate(currentCollection));
    items.appendChild(itemToAdd);

    currentCollection.dataset.cabFormCollectionItemIndex++;
    updateOrderFields(currentCollection);

    const cabEvent = new Event('cabEvent', {bubbles: true});
    items.dispatchEvent(cabEvent);

    const addItemToCollectionEvent = new Event('addItemToCollectionEvent', {bubbles: true});
    items.dispatchEvent(addItemToCollectionEvent);
}

function buttonsForItemCreate(currentCollection) {

    let buttonsRowContainer = document.createElement('div');
    buttonsRowContainer.classList.add('row');

    let buttonsContainer = document.createElement('div');
    buttonsContainer.classList.add('col-12');
    buttonsContainer.classList.add('mb-2');
    buttonsContainer.appendChild(buttonRemoveCreate(currentCollection));

    if (currentCollection.dataset.cabFormCollectionOrder === 'true') {
        buttonsContainer.appendChild(buttonUpCreate(currentCollection));
    }

    buttonsRowContainer.appendChild(buttonsContainer);

    return buttonsRowContainer;
}

function buttonRemoveCreate(currentCollection) {
    let button = document.createElement('button');
    button.type = 'button';
    button.innerHTML = 'Supprimer';
    button.classList.add('btn');
    button.classList.add('btn-danger');
    button.classList.add('btn-sm');
    button.classList.add('float-end');
    button.dataset.cabFormCollectionButtonRemove = currentCollection.dataset.cabFormCollectionName;
    button.addEventListener('click', elementRemoveEventListener);

    return button;
}

function buttonUpCreate(currentCollection) {
    let button = document.createElement('button');
    button.type = 'button';
    button.innerHTML = 'Remonter';
    button.classList.add('btn');
    button.classList.add('btn-secondary');
    button.classList.add('btn-sm');
    button.classList.add('float-end');
    button.dataset.cabFormCollectionButtonUp = currentCollection.dataset.cabFormCollectionName;
    button.addEventListener('click', elementUpEventListener);

    return button;
}

function elementUpEventListener(event) {

    let buttonUp = event.currentTarget;
    const currentCollectionName = buttonUp.dataset.cabFormCollectionButtonUp;
    const currentCollection = document.querySelector('[data-cab-form-collection-name="' + currentCollectionName + '"]');
    const itemToUp = buttonUp.closest('[data-cab-form-collection-item="' + currentCollectionName + '"]');
    const currentCollectionItems = buttonUp.closest('[data-cab-form-collection-items="' + currentCollectionName + '"]');
    const orderField = itemToUp.querySelector('[data-cab-form-collection-order-field="' + currentCollectionName + '"]');

    if (parseInt(orderField.value) === 0) {
        const messageUp = currentCollection.dataset.cabFormCollectionMessageUpErreur ?? 'Vous ne pouvez pas remonter cet élément !';

        notyf.open({
            type: 'danger',
            message: messageUp
        });
        return;
    }

    currentCollectionItems.insertBefore(itemToUp, itemToUp.previousElementSibling);
    updateOrderFields(currentCollection);
}

function updateOrderFields(currentCollection) {
    const currentCollectionName = currentCollection.dataset.cabFormCollectionName;
    const collectionOrder = currentCollection.dataset.cabFormCollectionOrder ?? false;
    if (collectionOrder !== false) {
        let index = 0;
        currentCollection
            .querySelectorAll('[data-cab-form-collection-item="' + currentCollectionName + '"]')
            .forEach(currentCollectionItem => {
                let orderField = currentCollectionItem.querySelector('[data-cab-form-collection-order-field="' + currentCollectionName + '"]');
                orderField.value = index;
                index++;
            });
    }
}

function elementRemoveEventListener(event) {
    event.preventDefault();
    let buttonRemove = event.currentTarget;
    const currentCollectionName = buttonRemove.dataset.cabFormCollectionButtonRemove;
    const currentCollection = document.querySelector('[data-cab-form-collection-name="' + currentCollectionName + '"]');

    const numberItemMin = currentCollection.dataset.cabFormCollectionItemMin ?? 0;
    const messageMin = currentCollection.dataset.cabFormCollectionMessageMin ?? 'Vous ne pouvez plus supprimer d\'élément !';

    if (parseInt(currentCollection.dataset.cabFormCollectionItemIndex) - 1 < numberItemMin) {
        notyf.open({
            type: 'danger',
            message: messageMin + ' (minimum : ' + numberItemMin + ')'
        });
        return;
    }

    buttonRemove.closest('[data-cab-form-collection-item="' + currentCollectionName + '"]').remove();
    currentCollection.dataset.cabFormCollectionItemIndex--;
    updateOrderFields(currentCollection);
}

function stringToDom(string) {
    const placeholder = document.createElement('div');
    placeholder.innerHTML = string;

    return placeholder.firstElementChild;
}

function addFocus(itemToAdd) {
    let inputField = itemToAdd.querySelector('input:not([type=hidden])');
    window.setTimeout(() => inputField.focus(), 100);
}