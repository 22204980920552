import Choices from "choices.js";

/**
 * Gestion des choicesjs
 *
 * data-cab-choice="true" pour activer le choicesjs sur le champs (text, select, select multiple)
 *
 * /!\ le champ doit avoir un id unique
 */

['DOMContentLoaded', 'cabEvent'].forEach(event => document.addEventListener(event, setChoices, false))

function setChoices()
{
    let choices = document.querySelectorAll('[data-cab-choice="true"]')
    choices.forEach(function(choice) {
        let choiceInput = document.getElementById(choice.id)
        new Choices(
            choiceInput,
            {
                shouldSort: true,
                itemSelectText: '',
                loadingText: 'chargement...',
                noResultsText: 'pas de résultat!',
            })
    })
}